// extracted by mini-css-extract-plugin
export var album = "Carousel-module--album--5pROZ";
export var button = "Carousel-module--button--OQfJV";
export var buttonOverlay = "Carousel-module--buttonOverlay--3ThtH";
export var carouselAreaContent = "Carousel-module--carouselAreaContent--zm1v2";
export var carouselButton = "Carousel-module--carouselButton--k5orN";
export var container = "Carousel-module--container--xnN16";
export var cover = "Carousel-module--cover--HR2QE";
export var current = "Carousel-module--current--5Esqq";
export var disabled = "Carousel-module--disabled--IdmYt";
export var frame = "Carousel-module--frame--pt1Bz";
export var frameBackground = "Carousel-module--frameBackground--d5gYT";
export var icon = "Carousel-module--icon--5ErGI";
export var link = "Carousel-module--link--ozhXJ";
export var musicName = "Carousel-module--musicName--wiN5W";
export var next = "Carousel-module--next--UWqFT";
export var previous = "Carousel-module--previous--TlAyp";
export var soon = "Carousel-module--soon--RrrZ7";
export var title = "Carousel-module--title--ZWIOF";